.categories-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  img {
    max-width: 100%;
  }

  li {
    flex-basis: 50%;
    padding: 0 4px;
  }
}
