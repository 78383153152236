.login-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  label,
  input {
    font-size: 0.9rem;
  }
}

.form-control {
  margin-bottom: 20px;
}

.error-span {
  font-size: 0.85rem;
  color: $alert;
  margin-top: 8px;
}

.user-menu {
  z-index: 100000 !important;

  .MuiMenu-paper {
    box-shadow: none;
  }

  .MuiMenu-list {
    padding: 0;

    li {
      color: $black;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
      padding: 0;
      display: block;
      width: 100%;
      min-height: unset;

      a {
        padding: 12px 16px;
        display: block;
        text-align: left;
      }

      &:first-child {
        padding: 16px;
        font-size: 1.1rem;
        color: $white;
        background: $black;
        text-align: center;
        cursor: default;

        &:hover {
          background: $black;
        }
      }
    }
  }
}

.chip {
  color: $white !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;

  &.chip1 {
    background: $registered;
  }

  &.chip2 {
    background: $sent;
  }

  &.chip3 {
    background: $completed;
  }

  &.chip4 {
    background: $alert;
  }
}

.minor-link {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}
