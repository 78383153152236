.MuiTab-textColorPrimary {
  color: $black !important;
  font-family: 'Quicksand', 'Open Sans' !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $black !important;
  font-family: 'Quicksand', 'Open Sans' !important;
}

.PrivateTabIndicator-colorPrimary-3 {
  background-color: $black !important;
  font-family: 'Quicksand', 'Open Sans' !important;
}

.MuiTabs-indicator {
  background-color: $black !important;
  font-family: 'Quicksand', 'Open Sans' !important;
  height: 1px !important;
}
