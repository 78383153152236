.footer-container {
  background: $white;
  padding: 20px 0px 6px;
  color: $black;
  border-top: 1px solid $color-1;

  .title {
    font-weight: 500;
    font-size: 0.85rem;
    padding: 0 0 10px 0;
    margin: 0;
    letter-spacing: 1px;
    color: $black;
  }

  .row {
    margin-bottom: 0;
  }

  * {
    font-family: 'Quicksand', 'Open Sans';
  }
}

.footer-row {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.wrapper.wrapper-copyright {
  border-top: 1px solid $color-1;
}

.footer-columns {
  display: flex;
  width: 100%;
  flex-direction: column;

  & > div {
    margin-bottom: 20px;
  }
}

.footer-links {
  margin: 0;
  padding: 0;
  list-style-type: none;

  div {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  a,
  div {
    text-decoration: none;
    color: $black;
    display: block;
    transition: all 0.2s ease-in-out;
    text-align: left;
    font-size: 0.7rem;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      opacity: 0.7;
    }
  }
}

.modal-newsletter-text {
  margin-bottom: 16px;
  h4 {
    margin-top: 0;
    font-size: 1.2rem;
    padding-right: 24px;
  }

  span,
  strong {
    font-size: 0.8rem;
  }
}

.newsletterpopupicon {
  color: $success;
  font-size: 3rem;
  align-self: center;
  margin-bottom: 20px;
}

.wrapper.newsletter {
  margin-bottom: 20px;
  border-bottom: 1px solid $color-1;
}

.footer-newsletter {
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;

  input {
    background: transparent;
    color: $black;
    padding-right: 70px;
  }

  .MuiInput-underline:before {
    border-color: $black;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: $black;
  }

  label {
    color: $black;
    z-index: 1;
    left: 4px;
    font-size: 1rem;
    top: 1px;

    &.MuiFormLabel-root.Mui-focused {
      color: $black;
    }
  }

  .MuiInput-underline:after {
    border-color: $black;
    border-width: 1px;
  }
}

.copyright {
  font-size: 0.7rem;
  align-self: center;
  text-align: center;
  width: 100%;
}

.footer-social-icons {
  align-self: center;
  padding: 10px;
}

.footer-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: $black;
  display: inline-block;
  vertical-align: middle;
  color: $white;
  text-align: center;
  line-height: 36px;
  font-size: 1.4rem;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.footer-newsletter label {
  font-family: 'Quicksand', 'Open Sans' !important;
}

.newsletter-form {
  position: relative;

  button {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    font-size: 0.7rem;
    border-radius: 0;
    background: $white;
    color: $black;
    border: 1px solid $black;
  }

  label {
    font-size: 0.7rem;
  }
}
