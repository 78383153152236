.table {
  display: block;

  * {
    font-family: 'Quicksand', 'Open Sans' !important;
  }

  tr {
    display: block;
    position: relative;
    border-bottom: 1px solid $black;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
  }

  thead {
    display: none;

    th {
      font-family: 'Quicksand', 'Open Sans' !important;
      font-size: 0.7rem !important;
    }
  }

  tbody {
    display: block;

    td {
      display: block;
      text-align: left;
      font-weight: normal;
      font-size: 1rem;
      border: 0 none;

      &:first-child {
        text-align: left;
        padding-right: 84px;
      }

      &:last-child {
        text-align: right;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    tr:hover {
      background: $color-2;
    }
  }

  .hidden {
    vertical-align: bottom;
    margin-right: 20px;
  }
}

.step3 .table tbody td:last-child {
  position: static;
  text-align: left;

  &.not-updateable {
    text-align: left;
  }
}

.step3 .table tbody td:first-child {
  padding-right: 0;
}
