.MuiFormControl-root.error .MuiInput-root {
  border-bottom: 2px solid red;

  &:before {
    border-bottom: 0;
  }
}

.MuiInputLabel-formControl {
  left: -6px !important;
  top: 6px !important;

  &.select-label {
    left: 8px !important;
  }
}

.MuiRadio-root {
  svg {
    color: $black;
  }
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='checkbox'],
textarea,
.billing-prefecture-label {
  font-family: 'Quicksand', 'Open Sans' !important;
  font-size: 0.9rem !important;
}

.MuiInputBase-multiline {
  padding: 6px !important;
}

textarea {
  padding: 0px !important;
}

.MuiFormControlLabel-label {
  font-size: 0.85rem !important;
}

.shipping-methods,
.payment-methods {
  .MuiFormControlLabel-label {
    font-size: 0.9rem !important;
  }
}

[overlayclassname='info-popover'] {
  .MuiPaper-root {
    padding: 24px;
    font-size: 1rem;
    max-width: 300px;
    box-shadow: 0px 0px 20px -6px $black;
  }
}

.payment-methods {
  h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .info-icon {
    margin-left: 4px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    line-height: 16px;
    border-radius: 50%;
    border: 1px solid $disabled;
    font-size: 1rem;
    text-align: center;
  }
}

.MuiAutocomplete-inputRoot {
  background: none !important;
}

input::placeholder {
  font-family: 'Quicksand', 'Open Sans' !important;
}

input::-webkit-input-placeholder {
  font-family: 'Quicksand', 'Open Sans' !important;
}

input::-moz-placeholder {
  font-family: 'Quicksand', 'Open Sans' !important;
}

input:-ms-input-placeholder {
  font-family: 'Quicksand', 'Open Sans' !important;
}

input:-moz-placeholder {
  font-family: 'Quicksand', 'Open Sans' !important;
}
