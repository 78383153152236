.pagination {
  margin: 0 0 20px;

  .MuiPagination-ul {
    justify-content: flex-end;
  }

  .MuiPaginationItem-root {
    color: $black;
    width: 18px;
    height: 18px;
    min-width: 18px;
    font-size: 0.7rem;
    font-family: 'Quicksand', 'Open Sans';
  }

  .MuiPaginationItem-page {
    &.Mui-selected {
      background-color: $black !important;
      color: $white !important;

      &:hover {
        background-color: $black !important;
      }
    }

    &:hover {
      background-color: $color-3 !important;
    }
  }
}
