.headerContainer {
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  background: hsla(0, 0%, 100%, 0.3);
  height: 40px;
  z-index: 100000;
  position: fixed;
  flex-wrap: nowrap !important;
  backdrop-filter: blur(5px);

  &.home-page-header {
    .header-icon {
      fill: $black;
    }

    .mini-cart span {
      color: $black;
      border-color: $black;
      line-height: 1.05;
    }

    .menu-icon-align span,
    .icon-menu:before {
      color: $black;
    }
  }

  & > div {
    color: $white;
  }

  & .MuiInputBase-root .MuiSvgIcon-root {
    fill: $white;
  }

  & .MuiInputAdornment-root {
    background-color: $black;
    height: 100%;
    border-radius: 4px;
    padding: 1px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  & .MuiInput-underline:before {
    border-color: $white;
  }

  & .MuiInput-underline:after {
    border-color: #fff;
  }

  label {
    color: $white;
  }

  & .MuiFormLabel-root.Mui-focused {
    color: $white;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: $black;
  }
}

.header-icon {
  fill: $black;
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.menuIcon {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  cursor: pointer;
}

.headerActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .user-icon {
    padding-bottom: 2px;
  }
}

.logo-image {
  max-width: 100%;
  width: 200px;
  vertical-align: baseline;
}

.logo {
  text-align: center;
}

.menu-icon-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .header-icon {
    margin-right: 0;
    margin-top: 4px;
  }

  span {
    color: $black;
    font-size: 0.8rem;
    font-family: 'Quicksand', 'Open Sans';
    margin-left: 4px;
    font-weight: 500;
  }

  i {
    height: 1px;
    width: 16px;
    background: $black;
    display: block;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.icon-search {
  font-size: 1.2rem;
  color: $black;
  padding: 10px 2px;
  cursor: pointer;
}

.search-relative-container {
  position: relative;
}

.main-menu-search-container {
  width: 280px;
  position: absolute;
  top: 100%;
  right: 0;
  background: $white;
  padding: 12px;
  border: 1px solid $color-3;

  * {
    font-family: 'Quicksand', 'Open Sans' !important;
  }

  > div {
    width: 100%;
    margin-bottom: 20px;
  }

  & .MuiInputBase-root {
    background: $white;
  }

  .MuiFormControl-root {
    position: relative;

    .minor-loading {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 0.6rem;
      margin-top: 6px;
      z-index: 1;
    }
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot {
    padding-right: 60px;
  }

  label {
    color: $color-3 !important;
    z-index: 1;
    left: 4px !important;
    top: 0 !important;
  }

  .MuiInput-underline:after {
    border-color: $black;
  }

  svg {
    color: $color-3 !important;
  }
}

.MuiAutocomplete-popper {
  z-index: 100000 !important;
}
