.productImage {
  height: 340px;
}

.mainProductPhotosContainer {
  max-width: 60% !important;
  margin: 0 auto !important;
}

.pagination {
  .MuiPaginationItem-root {
    width: 28px;
    height: 28px;
    min-width: 28px;
    font-size: 0.8rem;
  }
}

.minHeightForNewsletter {
  min-height: 300px;
}
