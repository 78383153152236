@import 'src/styles/colors.scss';

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

html {
  font-size: 16px;
}

body {
  font-family: 'Quicksand', 'Open Sans';
  margin: 0;
  padding: 0;

  * {
    font-family: 'Quicksand', 'Open Sans';
  }
}

.MuiInputLabel-root {
  font-family: 'Quicksand', 'Open Sans' !important;
}

.pointercursor {
  cursor: pointer;
  font-size: 1.4rem;
  position: absolute;
  top: 10px;
  right: 10px;
}

img {
  vertical-align: middle;
}

.content {
  margin-bottom: 50px;
  min-height: 600px;

  .content {
    min-height: unset;
  }

  &.user,
  &.static-content,
  &.cart-page,
  &.checkout,
  &.wishlist,
  &.orders-page,
  &.contact-page,
  &.productPage,
  &.notfound,
  &.unsubscribe {
    padding-top: 110px;
  }

  &.catalog {
    padding-top: 60px;
  }
}

.relevant-products {
  .hidden {
    display: none;
  }
}

.hidden {
  display: inline-block;
  font-size: 0.8rem;
}

ul {
  margin: 0;
  padding: 0;
}

.MuiSelect-select,
.MuiList-root * {
  font-family: 'Quicksand', 'Open Sans' !important;
  font-size: 0.9rem !important;
}

.row {
  width: 100%;
  margin-bottom: 50px;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 10px;

  &.nopadding {
    padding: 0;
  }

  &.small {
    max-width: 880px;
  }

  &.smaller {
    max-width: 500px;
  }
}

.grid-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.text-center {
  text-align: center;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.minor-text {
  color: $disabled;
  font-size: 0.8rem;

  &:before {
    display: none;
  }
}

.MuiAutocomplete-root .MuiFormControl-root {
  padding-left: 0 !important;
  padding-right: 0 !important;

  &.error > div {
    border: 2px solid $alert;
  }
}

h3,
h1,
h2,
strong {
  font-weight: 500;
}

// ACTIONS
.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.separate {
    justify-content: space-between;
  }

  .minor-link.mrl12,
  .button.next {
    margin-left: 2px !important;
  }

  &.login-actions {
    flex-direction: column;
  }

  * {
    margin-bottom: 6px;
  }
}

.minHeightForNewsletter {
  min-height: 0;
}

// PADDINGS, MARGINS
.pr0 {
  padding-right: 0 !important;
}

.p2 {
  padding: 12px;
}

.mrl12 {
  margin-left: 12px !important;
  font-weight: 400;
}

// TITLES
.MuiTypography-root {
  font-family: 'Quicksand', 'Open Sans' !important;
}

.section-title {
  font-size: 1.4rem;
  font-weight: 300;
  text-align: left;
  margin: 0 0 20px;
  color: $black;
  letter-spacing: 1px;
  font-family: 'Tenor Sans', serif;
}

.section-title-small {
  font-size: 1.4rem;
  font-weight: 300;
  text-align: left;
  margin: 0 0 20px;
  color: $black;
  letter-spacing: 1px;
  font-family: 'Tenor Sans', serif;
}

.withCursor {
  cursor: pointer;
}

.page-title {
  font-size: 1.4rem;
  font-weight: 300;
  text-align: left;
  margin: 0 0 20px;
  color: $black;
  letter-spacing: 2px;
  font-family: 'Tenor Sans', serif;
}

// LOADER
.loading {
  &.full {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 140000;
  }

  span {
    padding: 16px;
    border-radius: 6px;
    background: $white;
    box-shadow: 0px 0px 24px -12px $black;
  }
}

// SLIDER
[class^='PrivateValueLabel-circle'],
.MuiSlider-thumb,
.MuiSlider-mark,
.MuiSlider-valueLabel {
  background: $black !important;
}

.MuiSlider-track {
  background: $black !important;
}

.mr0 {
  margin-right: 0 !important;

  & .catalog-pagination {
    margin-right: 0;
  }
}

.mb0 {
  margin-bottom: 0 !important;
}

@import 'src/styles/buttons.scss';
@import 'src/styles/tabs.scss';
@import 'src/styles/slick.scss';
@import 'src/styles/table.scss';
@import 'src/styles/cart.scss';
@import 'src/styles/catalog.scss';
@import 'src/styles/product.scss';
@import 'src/styles/contact.scss';
@import 'src/styles/categories.scss';
@import 'src/styles/checkout.scss';
@import 'src/styles/user.scss';
@import 'src/styles/alert.scss';
@import 'src/styles/loader.scss';
@import 'src/styles/header.scss';
@import 'src/styles/menu.scss';
@import 'src/styles/inputs.scss';
@import 'src/styles/footer.scss';
@import 'src/styles/notfound.scss';
@import 'src/styles/filters.scss';
@import 'src/styles/pagination.scss';
@import 'src/styles/wishlist.scss';
@import 'src/styles/orders.scss';
@import 'src/styles/static.scss';
@import 'src/styles/cookies.scss';
@import 'src/styles/accordion.scss';
@media only screen and (min-width: 469px) {
  @import 'src/styles/responsive2.scss';
}

@media only screen and (min-width: 769px) {
  @import 'src/styles/responsive1.scss';
}
