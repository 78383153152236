.orders-page {
  .table tbody tr {
    cursor: pointer;
  }

  .table tbody td:last-child {
    padding: 0;
    & .MuiChip-label {
      font-size: 0.6rem;
    }
  }

  td {
    word-break: break-all;
  }
}

.table tbody .order-details td:last-child {
  text-align: left;
  position: static;
  padding-right: 0;
}

.order-details {
  cursor: default !important;
}

.table tbody .order-details .cart-content .table tbody td:last-child {
  text-align: left;
}

.table tbody tr.order-details:hover,
.table tbody tr.order-details tr:hover {
  background: $white;
  cursor: default;
}

.billing-shipping-order-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}

.billing-shipping-box {
  padding: 24px;
  border: 1px solid $color-3;

  h3 {
    margin: 0;
    margin-bottom: 12px;
    font-size: 1rem;
  }
}

.order-info {
  margin-bottom: 6px;
  font-size: 0.85rem;

  &:last-child {
    margin-bottom: 0;
  }
}

h3 {
  font-size: 1rem;
}
