.contact-page {
  .MuiInputLabel-root {
    font-size: 0.9rem;
  }

  .wrapper {
    max-width: 500px;
  }

  .form-control {
    margin-bottom: 20px;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}
