.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
