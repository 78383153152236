.submenu-container {
  display: none;
  padding: 8px;
  background: $white;
  width: 100%;
  margin-top: 8px;

  > * {
    color: $black;
    margin-bottom: 12px;
    font-size: 9px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.open {
    display: block;
  }
}

.submenu-item {
  cursor: pointer;
  color: $white;
  flex-direction: row;
  text-align: right;
  flex-basis: 50%;

  i {
    color: $black;
  }

  &.rotate {
    transform: rotate(180deg);
    display: flex;
  }
}

.menu-item-category {
  flex-grow: 1;
  flex-basis: 50%;
  font-size: 1.4rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}

.menu-item {
  color: $black;
  font-size: 1.1rem;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}

.MuiDrawer-root {
  z-index: 100000 !important;
}

.MuiDrawer-paper {
  & .MuiSvgIcon-root {
    color: $white;
  }
}

.icon-menu:before {
  color: $black;
  font-size: 1.1rem;
}

.main-menu {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  background-color: rgba(249, 249, 249, 1);

  * {
    font-family: 'Quicksand', 'Open Sans';
  }

  a {
    color: $black;
    font-size: 0.85rem;
  }
}

.MuiDrawer-paperAnchorLeft {
  right: auto !important;
  padding: 20px;
  background-color: $black !important;
  width: 100%;
  max-width: 340px;
  z-index: 1000000 !important;
}

.main-menu-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;

  svg {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  > div {
    flex-grow: 1;
    text-align: center;
    padding-left: 40px;
  }
}

.menu-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  padding: 6px !important;
}

.menuIcon {
  font-size: 1.85rem;
  color: $white;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $color-1;
  }
}

.closeMenu {
  font-size: 1.85rem;
  color: $black;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
