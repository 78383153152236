.card-actions {
  align-items: center !important;
  justify-content: space-between;
  cursor: default;
  padding-top: 0 !important;

  &.loading-actions {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
      pointer-events: none;
    }
  }
}

.icon-heart-empty {
  font-size: 1.2rem;
}

.product {
  width: 100%;
  overflow: hidden;
  display: inherit;
  padding: 0;
  position: relative;

  &.navlink {
    text-decoration: none;

    p {
      min-height: 40px;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      margin-bottom: 10px;
      text-align: left;
      color: $black !important;

      &.in-stock {
        margin: 8px 0 0;
        min-height: unset;
        text-align: left;
        padding: 0;
        -webkit-box-orient: unset;
        box-orient: unset;
        -webkit-line-clamp: unset;
        line-clamp: unset;
        overflow: unset;
        display: block;

        span {
          position: relative;
          display: inline-block;
          padding: 0 0 0 10px;

          &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            left: 0;
            top: 50%;
            margin-top: -3px;
            background: $success;
            border-radius: 20px;
          }
        }

        &.not span:before {
          background: $alert;
        }
      }

      &.product-code {
        font-size: 0.8rem;
        font-weight: 300;
        display: block;
        min-height: unset;
        line-clamp: unset;
        overflow: auto;
        color: $black !important;
        margin-bottom: 0;
        padding-right: 4px;
      }
    }

    &:hover {
      .MuiCardMedia-root > div {
        transform: scale(1.1);
      }
    }
  }
}

.product-action {
  border-radius: 50% !important;

  &.MuiIconButton-root {
    padding: 6px;
    color: $black;
  }
}

.add-to-cart {
  border: none;
  padding: 2px;
  background: none;
  color: $black;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  &.no-margin {
    margin-bottom: 0;
  }

  &.for-product-page {
    & > div {
      justify-content: flex-start;
      width: auto;
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    font-weight: 500;
    color: $black;
    width: 100%;
    font-family: 'Quicksand', 'Open Sans';

    & > span {
      margin-right: 4px;
      font-size: 0.5rem;
      text-decoration: line-through;
      color: $black;
      font-family: 'Quicksand', 'Open Sans';
    }
  }

  .discount {
    color: $alert;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 4px;
    background: $black;
    margin-left: 10px;

    &.absolute {
      position: absolute;
      top: 6px;
      left: 6px;
      padding: 3px;
      z-index: 1;
      background: $black;
      color: $white;
      margin-left: 0;
      font-size: 0.85rem;
      box-shadow: 0px 0px 6px 0px $black;
    }
  }
}

.productPage {
  .slick-track {
    margin-left: 0;
  }

  .slick-dots {
    margin-top: 20px;
  }

  .MuiGrid-container .price-container {
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .MuiGrid-container .product-code {
    margin-bottom: 20px;
    color: $black;
    font-weight: 300;
  }
}

.productPage,
.catalog {
  .product-title {
    .headerTitle {
      text-align: left;
      font-weight: 400 !important;
      font-size: 2.1rem !important;
      margin: 0;
      margin-bottom: 20px;
      padding: 0;
      margin-top: 20px;

      &.small {
        font-size: 1.5rem !important;
        text-align: center;
      }
    }
  }
}

.mainProductRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.mainProductRightSection {
  .product-description {
    margin-bottom: 16px;
    font-family: 'Quicksand', 'Open Sans';
  }

  .in-stock {
    margin: 0 0 12px;
    text-align: left;
    padding: 0;
    display: block;
    font-size: 1rem;
    font-weight: 500;

    span {
      position: relative;
      display: inline-block;
      padding: 0 0 0 10px;

      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        left: 0;
        top: 50%;
        margin-top: -3px;
        background: $success;
        border-radius: 20px;
      }
    }
  }
}

.mainProductPhotosContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  overflow: hidden;

  .price-container {
    margin: 0 !important;
  }

  .is-new {
    text-align: center;
  }

  img {
    width: 100%;
  }

  + div {
    padding-left: 0;
  }
}

.product-page-actions {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;

  .add-to-cart {
    width: auto;

    .header-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.headerTitle {
  font-size: 0.85rem;
  font-family: 'Quicksand', 'Open Sans' !important;
  font-weight: 300 !important;
  color: $black;
  text-align: left;
  margin-bottom: 12px;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  padding: 0;
}

.is-new {
  font-weight: 500;
  font-family: 'Quicksand', 'Open Sans' !important;
  font-size: 0.7rem;
  color: $black;
  background-color: $white;
  position: absolute;
  z-index: 1;
  padding: 7px 6px 9px;
  right: 0;
  top: 12px;
  text-align: center;
  line-height: 1px;
}

.subHeader {
  font-size: 0.85rem !important;
  color: $black !important;
  text-align: left;
  margin-bottom: 12px;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

.productImage {
  transition: all 0.2s ease-in-out;
  position: relative;
  height: 180px;

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
  }

  &.show {
    img {
      opacity: 1;
    }
  }

  &.no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 68px;
  }
}

.product-description {
  margin-bottom: 10px;
  text-align: left;
  color: $black !important;
}

.navlink.product {
  .subHeader {
    height: 14px;
  }

  .MuiCardContent-root {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.MuiPaper-root.MuiCard-root {
  border-radius: 0px !important;
}
