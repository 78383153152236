.static-content-html {
  p {
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
    font-size: 0.9rem;
    line-height: 1.6;
  }

  strong,
  b {
    font-weight: 600;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
    margin-bottom: 20px;
  }
}
