.slick-prev,
.slick-next {
  margin-top: -10px;

  &:before {
    font-size: 1.1rem;
    font-family: 'Quicksand', 'Open Sans' !important;
  }
}

.homeSlickSlide {
  a {
    display: block;
    padding: 0;
  }

  img {
    width: 100%;
    outline: none;
    object-fit: contain;
  }
}

.slick-next {
  right: -10px;
  z-index: 1;

  &:before {
    content: '>';
    color: $black;
  }
}

.slick-prev {
  left: -10px;
  z-index: 1;

  &:before {
    content: '<';
    color: $black;
  }
}

.slick-dots {
  position: static;
}

.slider-product {
  padding: 0 4px;
}

.slick-dots li button:before {
  font-size: 0.85rem;
  content: '';
  background: $black;
  border-radius: 50%;
  display: none;
}

.slick-dots li button {
  font-size: 0.85rem;
  color: $black;
  line-height: 2px;
  width: 20px;
  height: 19px;
  background: $white;
  opacity: 0.35;
  border-radius: 50%;
  padding: 0;
  border: 1px solid $black;
}

.slick-dots li.slick-active button {
  color: $black;
  opacity: 1;
}
