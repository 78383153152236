.button {
  background: transparent;
  border: 0 none;
  font-size: 0.9rem;
  color: $black;
  transition: all 0.2s ease-in-out;
  font-family: 'Quicksand', 'Open Sans';
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.MuiButtonBase-root {
    border: 1px solid $color-3;

    &.green {
      background-color: green;
      color: #fff;
    }
  }

  &.green {
    background: $success;
  }

  &.red {
    background: $alert;

    &.refresh {
      color: $white;
    }
  }

  &.next {
    background: $white !important;
    border: 1px solid $color-3;
    color: $black;

    a {
      font-weight: 400;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
  }
}

a {
  background: transparent;
  border: 0 none;
  font-size: 0.9rem;
  font-family: 'Quicksand', 'Open Sans';
  color: $black;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  padding: 4px;
  font-weight: 500;
  text-align: center;

  &.navigation {
    &:hover {
      opacity: 0.7;
    }

    &.green {
      background: $success;
    }

    &.red {
      background: $alert;
    }

    &.next {
      color: $white;
      border: 0 none;
      font-size: 0.9rem;
      background: $black;
      border-radius: 4px;
      padding: 8px;
      min-width: 150px;
    }
  }
}

.button.next[disabled] {
  cursor: default;
  background: $color-3 !important;
  color: $disabled;
  opacity: 0.5;
}
