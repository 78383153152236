.page-not-found-countainer {
  max-width: 600px;
  margin: 0 auto;
  min-height: 400px;
  text-align: center;
}

.page-not-found-title {
  text-align: center;
  font-size: 2.85rem;
  color: $disabled;
}

.page-not-found-subtext {
  font-size: 0.85rem;
  color: $disabled;
  margin-bottom: 30px;
}
