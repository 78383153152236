.cart-description {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    margin-right: 10px;
  }
}

.mini-cart {
  text-decoration: none;
  position: relative;
  line-height: 0;
  background: transparent;
  border: 0 none;
  cursor: pointer;

  i {
    line-height: 1;
  }

  span {
    position: absolute;
    font-size: 0.48rem;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    color: $black;
    text-align: center;
    line-height: 1.05;
    top: 0;
    right: -2px;
    border: 1px solid $black;
  }
}

.user-icon {
  img {
    width: 30px;
    height: 30px;
    vertical-align: baseline;
  }
}

.user-icon i {
  margin-left: 6px !important;
}

.user-icon,
.usermenu-container {
  svg {
    width: 17px;
    height: 17px;
  }

  i {
    font-size: 1.3rem;
    color: $white;
    margin-left: 6px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      color: $color-1;
    }
  }
}

.totalPrice {
  font-size: 1rem;
  margin-right: 10px;
}

.usermenu-container {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 20px;
    background: $success;
    top: 0;
    right: 0;
  }

  i svg {
    width: 17px;
    height: 17px;
    margin-top: 5px;
  }
}

.removeCancel {
  font-size: 1.4rem;
  color: $alert;
  cursor: pointer;
  margin-left: 12px;
}

.cart-totals-container {
  border: 1px solid $color-3;
}

.cart-totals-row {
  padding: 8px;
  margin-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;

  &.bold {
    font-weight: 500;
  }

  &:last-child {
    margin-bottom: 0;
    font-size: 1.1rem;
  }
}

.cart-totals-name {
  padding-right: 12px;
}

.cart-totals-value {
  font-weight: 500;
}

.cart-item-initial-price {
  margin-right: 10px;
  font-size: 0.7rem;
  text-decoration: line-through;
  color: $black;
}

.cart-totals-row-title {
  background: $white;
  margin-bottom: 0;
  color: $black;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 1px;
  border-bottom: 1px solid $color-3;
}

.icon-shopping-basket {
  font-size: 1.85rem;
  color: $white;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $color-1;
  }
}

.cart-item-image {
  max-width: 100px;
  max-height: 100px;
}

.mini-cart-drawer-wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .cart-item-image {
    max-width: 30px;
  }

  .icon-trash-empty {
    font-size: 0.9rem;
  }
}

.mini-cart-drawer-flex {
  display: flex;
  gap: 4px;
  margin-bottom: 12px;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  > div:first-child {
    width: 140px;
  }

  > div:nth-child(2) {
    max-width: 40px;
    text-align: center;
  }

  .cart-item-initial-price,
  .totalPrice {
    margin-right: 0;
    text-align: center;
  }

  .cart-description {
    align-items: center;

    span {
      font-size: 0.7rem;
      overflow: hidden;

      &:last-child {
        padding-left: 4px;
      }
    }
  }

  .cart-item-initial-price {
    font-size: 0.7rem;
  }

  .totalPrice {
    font-size: 0.7rem;
  }

  .mini-cart-prices {
    flex-grow: 1;
  }
}

.mini-cart-drawer-items {
  flex-grow: 1;
  width: 100%;
}

.mini-cart-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  * {
    cursor: pointer;
    font-size: 0.9rem;
  }
}

.cart-page {
  a.navigation.next {
    background: $white;
    color: $black;
    border: 1px solid $color-3;
    font-weight: 400;
  }
}

.coupon-used {
  font-size: 0.7rem;
  margin-top: 6px;
  font-weight: 600;
}

.coupon-form {
  padding: 12px 110px 0 0;
  margin-top: 12px;
  position: relative;
  max-width: 320px;

  input,
  div {
    width: 100%;
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}
