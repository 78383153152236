.filters {
  flex-basis: 100%;
  width: 100%;
}

.filter-box {
  border: 1px solid $color-3;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.filter-title {
  padding: 8px;
  background: $white;
  color: $black;
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;

  &.orange {
    background: $black;
    color: $white;
  }
}

.filter-list {
  margin: 0;
  padding: 12px;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;

  &.prices {
    padding-left: 34px;
    padding-right: 34px;
    padding-top: 54px;
  }
}

.filter-option {
  padding: 8px 0 8px 24px;
  font-size: 0.7rem;
  position: relative;
  line-height: 1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    left: 0;
    top: 50%;
    margin-top: -9px;
    border: 1px solid $black;
    transition: all 0.2s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 3px;
    top: 50%;
    margin-top: -6px;
    background: $black;
    display: none;
  }

  &:hover {
    color: $black;

    &:before {
      border-color: $black;
    }
  }

  &.active {
    color: $black;

    &:before {
      border-color: $black;
    }

    &:after {
      display: block;
    }
  }
}

.selected-filters-list {
  margin: 0;
  padding: 12px;
  list-style-type: none;
}

.selected-filter-option {
  padding: 8px 0;
  line-height: 1;
  cursor: pointer;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    position: relative;
    display: inline-block;
    padding-right: 14px;
    font-size: 0.7rem;
    line-height: 1;
    font-weight: 500;

    &:after {
      content: 'x';
      position: absolute;
      line-height: 1;
      right: 4px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 0.7rem;
      color: $alert;
      font-weight: 500;
    }
  }
}

.filter-toggle {
  font-weight: 100;
  letter-spacing: 1px;
  font-size: 1.1rem;
  margin-bottom: 12px;
  background: none !important;
  border: 0 none;
  padding: 0;
  cursor: pointer;

  &.button.next {
    padding: 0 8px 4px;
  }
}

.filters-container {
  position: fixed;
  transform: translate(-500%, 0);
  transition: all 0.4s ease-in-out;
  top: 0;
  bottom: 0;
  background: $white;
  z-index: 140000;
  box-shadow: 0px 0px 20px 2px $black;
  padding: 12px;
  width: 100%;
  max-width: 300px;
  overflow-y: auto;

  &.open {
    transform: translate(0, 0);
    left: 0;
  }
}
