// Accordion Height
$a-height: 700px;

// Position text along bottom
$text-offset: $a-height - 90;

.accordion {
  width: 100%;
  height: $a-height;
  overflow: hidden;

  .about {
    text-align: center;
    font-size: 0.9rem;
    color: #666;

    a {
      color: blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul {
    width: 100%;
    display: table;
    table-layout: fixed;
    margin: 0;
    padding: 0;

    li {
      display: table-cell;
      vertical-align: bottom;
      position: relative;
      width: 16.666%; // 6 into 100
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 500ms ease;

      &.videoLi {
        div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }

      div {
        display: block;
        overflow: hidden;
        width: 100%;

        a {
          display: block;
          height: $a-height;
          width: 100%;
          position: relative;
          z-index: 3;
          padding: 15px 20px;
          box-sizing: border-box;
          color: #fff;
          text-decoration: none;
          transition: all 200ms ease;

          * {
            opacity: 1;
            margin: 0;
            width: 100%;
            text-overflow: ellipsis;
            position: relative;
            z-index: 5;

            white-space: nowrap;
            overflow: hidden;

            -webkit-transition: all 400ms ease;
            transition: all 400ms ease;
          }

          h2 {
            text-overflow: clip;
            font-size: 2rem;
            margin: 0 auto;
            display: flex;
            height: 100%;
            font-weight: 300;
            justify-content: center;
            align-items: center;
            font-family: 'Tenor Sans', serif;
            white-space: break-spaces;
            max-width: 210px;
          }

          p {
            top: $text-offset;
            font-size: 1rem;
          }
        }
      }
    }

    &:hover li,
    &:focus-within li {
      width: 8%;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    li:focus {
      outline: none;
    }

    &:hover li:hover,
    // allow users to tab through active slides
    li:focus,
    &:focus-within li:focus {
      width: 60%;

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      a {
        background: rgba(0, 0, 0, 0.4);

        * {
          opacity: 1;
          -webkit-transform: translateX(0);
          transform: translateX(0);
        }
      }
    }

    &:hover li {
      width: 8% !important;

      @media screen and (max-width: 600px) {
        width: 100% !important;
      }

      a * {
        opacity: 0 !important;
      }
    }

    &:hover li:hover {
      width: 60% !important;

      @media screen and (max-width: 600px) {
        width: 100% !important;
      }

      a {
        background: rgba(0, 0, 0, 0.4);

        * {
          opacity: 1 !important;
          -webkit-transform: translateX(0);
          transform: translateX(0);
        }
      }
    }
  }
}

// Stack items
@media screen and (max-width: 600px) {
  .accordion {
    height: auto;

    ul {
      display: table;
      table-layout: fixed;
      height: auto;

      li {
        position: relative;
        display: block;
        width: 100%;
        height: 140px;
      }
    }
  }

  .accordion ul li div a {
    height: 100%;
  }

  .accordion ul li div {
    height: 100%;
  }

  .accordion ul li div a h2 {
    font-size: 1.2rem;
  }
}
