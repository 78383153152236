$white: #fff;
$black: #000;
$color-1: #dddddc;
$color-2: #f2f2f2;
$color-3: #e1e1e1;
$disabled: #4b4d48;
$success: rgb(67, 145, 67);
$alert: rgb(252, 55, 55);
$warning: rgb(65, 77, 242);
$registered: rgb(65, 77, 242);
$purple: #fce700;
$sent: #f26522;
$completed: rgb(67, 145, 67);