.CookieConsent {
  align-items: center !important;
  background: $black !important;
}

.cookies-buttons {
  button {
    color: $white !important;
    border-radius: 4px !important;
    font-size: 0.85rem;

    &.next {
      background: $success !important;
    }
  }
}
.cookie-content {
  flex: 1 1 auto !important;
  font-size: 0.85rem;
}
