@import 'src/styles/colors.scss';

.tabsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.tabPanel,
.home-section {
  .slick-slide:nth-child(even) {
    margin-top: 60px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }
}

.productContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.offers-bg,
.home-section {
  .wrapper.small {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.last-banner-section {
  a {
    display: block;
    padding: 0;
  }

  img,
  video {
    width: 100%;
  }
}
